import React from "react"
import styled from "styled-components"
import { H2 } from "../../shared/Typography/Typography"
import GridContainer from "../../Layout/GridContainer"
import { device } from "../../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"
import StaffMemberBox from "../../MainPage/StaffMembers/StaffMemberBox"
import { staffList } from "./staffList"

const StyledStaffMembersGrid = styled.div`
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMembersBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 384px;
  column-gap: ${({ theme }) => theme.spaces[20]};
  width: 1410px;
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};

  @media ${device.lg} {
    width: 100%;
    max-width: 886px;
    margin: 0 auto;
    flex-wrap: wrap;
    height: auto;
    row-gap: ${({ theme }) => theme.spaces[40]};
    justify-content: flex-start;
  }
  @media ${device.xl} {
    max-width: 1180px;
  }
`

const StyledFutureText = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[40]}`};
  white-space: pre-wrap;
  text-align: left;
  max-width: 800px;

  @media ${device.xl} {
    max-width: 893px;
  }
`

const StaffMembersSection = styled.section`
  margin: ${({ theme }) => `${theme.spaces[64]} auto`};
`

const StyledMembersOverflow = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${device.lg} {
    overflow: hidden;
  }
`

export default function StaffMembers({ ...props }) {
  const { t } = useTranslation()
  return (
    <StaffMembersSection {...props}>
      <StyledStaffMembersGrid>
        <GridContainer>
          <StyledFutureText>{t("Za sukcesem FRENZY")}</StyledFutureText>
        </GridContainer>
        <StyledMembersOverflow>
          <StyledMembersBox>
            {staffList.map((el, key) => (
              <StaffMemberBox
                imgUrl={el.imgUrl}
                imgAlt={el.imgAlt}
                title={el.name}
                subtitle={el.spec}
                key={key}
              ></StaffMemberBox>
            ))}
          </StyledMembersBox>
        </StyledMembersOverflow>
      </StyledStaffMembersGrid>
    </StaffMembersSection>
  )
}
