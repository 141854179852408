import jedrzejSteszewskiImg from "../../../images/about-us/staffMembers/jedrzej-steszewski.png"
import hubertHabasImg from "../../../images/about-us/staffMembers/hubert-habas.png"
import bartlomiejZawadzkiImg from "../../../images/about-us/staffMembers/bartlomiej-zawadzki.png"
import agataModzelewskaImg from "../../../images/about-us/staffMembers/agata-modzelewska.png"
import michalSzafrugaImg from "../../../images/about-us/staffMembers/michal-szafruga.png"
import tomaszBorowskiImg from "../../../images/about-us/staffMembers/tomasz-borowski.png"

export const staffList = [
  {
    imgUrl: jedrzejSteszewskiImg,
    imgAlt: "Jędrzej Stęszewski - Board Member",
    name: "Jędrzej Stęszewski",
    spec: "Board Member",
  },
  {
    imgUrl: hubertHabasImg,
    imgAlt: "Hubert Habas",
    name: "Hubert Habas",
    spec: "Sales Director",
  },
  {
    imgUrl: tomaszBorowskiImg,
    imgAlt: "Tomasz Borowski",
    name: "Tomasz Borowski",
    spec: "PR Manager",
  },
  {
    imgUrl: bartlomiejZawadzkiImg,
    imgAlt: "Bartłomiej Zawadzki",
    name: "Bartłomiej Zawadzki",
    spec: "Head of Project Management",
  },
  {
    imgUrl: agataModzelewskaImg,
    imgAlt: "Agata Modzelewska",
    name: "Agata Modzelewska",
    spec: "Head of Post Production",
  },
]
