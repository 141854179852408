import React from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import {
  H2,
  ParagraphRegular,
  Disclaimer,
} from "../shared/Typography/Typography"
import Miniature from "../../images/about-us/aboutUsHeader.png"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledHeaderTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`
const StyledHeaderText = styled(ParagraphRegular)`
  margin: 0;
  @media ${device.lg} {
    margin: 0 auto;
    max-width: 520px;
  }
`
const StyledDisclaimer = styled(Disclaimer)`
  margin: 0 auto;
  text-align: center;
  width: 100%;
`
const StyledDisclaimerWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: ${({ theme }) => theme.spaces[16]};
  display: none;
  @media ${device.lg} {
    display: block;
  }
`
const StyledTextWrapper = styled.div`
  margin: ${({ theme }) =>
    `${theme.spaces[64]} 0 ${theme.spaces[40]} ${theme.spaces[16]}`};
  max-width: 520px;
  @media ${device.lg} {
    max-width: 710px;
    text-align: center;
    margin: ${({ theme }) => `${theme.spaces[64]} auto ${theme.spaces[40]} `};
  }
`
const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  min-height: 410px;
`
const StyledHeaderWrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`

export default function SiteHeader () {
  const { t } = useTranslation()
  return (
    <StyledHeaderWrapper>
      <StyledTextWrapper>
        <StyledHeaderTitle>
          {t("Tworzone przez pasjonatów dla graczy")}
        </StyledHeaderTitle>
        <StyledHeaderText>{t("Napędza nas pasja")}</StyledHeaderText>
      </StyledTextWrapper>
      <StyledMiniature src={Miniature}></StyledMiniature>
      <StyledDisclaimerWrapper>
        <StyledDisclaimer>
          {t("To tylko część osób stojących za sukcesem FRENZY")}
        </StyledDisclaimer>
      </StyledDisclaimerWrapper>
    </StyledHeaderWrapper>
  )
}
