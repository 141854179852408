import React from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import {
  H2,
  ParagraphRegular,
  SectionTitleBig,
  Disclaimer,
} from "../shared/Typography/Typography"
import { ExternalLink } from "../shared/Link/Link"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import careerBg from "../../images/about-us/career-bg.svg"
import { buttonStyles } from "../shared/Button/Button"

const StyledCareerGrid = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces[64]};
  position: relative;
  max-width: 1180px;
  @media ${device.lg} {
    margin-bottom: 0;
    margin: ${({ theme }) => ` ${theme.spaces[16]} auto ${theme.spaces[160]}`};
  }
`
const StyledTextBox = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  margin: 0 auto;
  box-sizing: border-box;
  padding: ${({ theme }) =>
    `${theme.spaces[40]} ${theme.spaces[16]} ${theme.spaces[120]}`};
  background-image: url(${careerBg});
  background-repeat: no-repeat;
  background-size: 980px 765px;
  background-position: calc(50vw - 15px) -148px;
  @media ${device.md} {
    padding: ${({ theme }) =>
      `${theme.spaces[80]} ${theme.spaces[80]} ${theme.spaces[120]}`};
  }
  @media ${device.lg} {
    padding: ${({ theme }) =>
      `${theme.spaces[120]} ${theme.spaces[100]} ${theme.spaces[120]}`};
    max-width: 880px;
    background-position: 180px -120px;
  }
  @media ${device.xl} {
    padding: ${({ theme }) =>
      `${theme.spaces[100]} ${theme.spaces[100]} ${theme.spaces[140]}`};
    max-width: 980px;
  }
`
const StyledTitle = styled(H2)`
  margin: ${({ theme }) => `0  ${theme.spaces[16]} ${theme.spaces[40]}`};
  @media ${device.xl} {
    margin: ${({ theme }) => `0  0 ${theme.spaces[40]}`};
  }
`
const StyledSubTitle = styled(H2)`
  font-size: ${({ theme }) => theme.fontSizes[30]};
  line-height: ${({ theme }) => theme.lineHeights[40]};
  margin: 0;

  @media ${device.lg} {
    font-size: ${({ theme }) => theme.fontSizes[40]};
    line-height: ${({ theme }) => theme.lineHeights[42]};
  }
`
const StyledText = styled(ParagraphRegular)`
  margin: ${({ theme }) => ` ${theme.spaces[16]} 0 ${theme.spaces[24]}`};
  max-width: 348px;
  white-space: pre-wrap;
  @media ${device.md} {
    max-width: 380px;
  }
`
const StyledQuoteBox = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colors.yellow}`};
  padding: ${({ theme }) => `${theme.spaces[32]} ${theme.spaces[24]}`};
  margin: -77px auto 0;
  background-color: white;
  z-index: 3;
  max-width: 343px;
  box-sizing: border-box;

  @media ${device.lg} {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    right: 0;
    margin: 0;
    transform: translateY(-50%);
    max-width: none;
    width: 580px;
    height: 191px;
    justify-content: center;
  }
`
const StyledQuoteText = styled(SectionTitleBig)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`
const StyledQuoteName = styled(Disclaimer)`
  opacity: 1;
  > span {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.black};
    opacity: 1;
  }
`

const StyledButton = styled(ExternalLink)`
  ${buttonStyles}
  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }
  display: inline-block;
`
const StyledTextWrapper = styled.div`
  margin: 0 auto;
  @media ${device.md} {
    margin: 0;
  }
`
const StyledCareerGuy = styled.div`
  position: absolute;
  left: 37px;
  bottom: 0;
  max-width: 180px;
  display: none;
  img {
    width: 100%;
  }
  @media ${device.lg} {
    display: block;
  }
`
const StyledRightPhoto = styled.div`
  position: absolute;
  height: 182px;
  width: 182px;
  display: none;
  right: 0;
  top: 40px;
  @media ${device.lg} {
    display: block;
  }
  @media ${device.xl} {
    right: -100px;
  }
`
const StyledLeftPhoto = styled.div`
  position: absolute;
  height: 182px;
  width: 182px;
  display: none;
  bottom: -91px;
  left: 0px;
  @media ${device.lg} {
    display: block;
  }
  @media ${device.xl} {
    bottom: -91px;
    left: -100px;
  }
`

const StyledQuoteWrapper = styled.div`
  @media ${device.lg} {
    max-width: 276px;
    margin-right: 16px;
  }
`
const StyledSectionWrapper = styled.div`
  position: relative;
  max-width: 980px;
  margin: 0 auto;
`
export default function Career({ ...props }) {
  const { t } = useTranslation()
  return (
    <StyledCareerGrid {...props}>
      <StyledTitle>{t("Kariera")}</StyledTitle>
      <StyledSectionWrapper>
        <StyledLeftPhoto>
          <StaticImage
            src="../../images/about-us/floatImgLeft.png"
            alt="frenzy crew"
          />
        </StyledLeftPhoto>
        <StyledRightPhoto>
          <StaticImage
            src="../../images/about-us/floatImgRight.png"
            alt="frenzy crew"
          />
        </StyledRightPhoto>
        <StyledTextBox>
          <StyledTextWrapper>
            <StyledSubTitle>{t("Czekamy na Ciebie")}</StyledSubTitle>
            <StyledText>{t("career.description")}</StyledText>
            <StyledButton
              sufixIcon
              color="secondary"
              href="https://rocketjobs.pl/brands/story/frenzy"
              target="_blank"
            >
              {t("Sprawdź oferty pracy")}
            </StyledButton>
          </StyledTextWrapper>
        </StyledTextBox>
        <StyledQuoteBox>
          <StyledCareerGuy>
            <StaticImage
              src="../../images/about-us/careerHero.png"
              alt={`Kinga Rybicka - ${t("kinga.position")}`}
            />
          </StyledCareerGuy>
          <StyledQuoteWrapper>
            <StyledQuoteText>{t("Praktyki")}</StyledQuoteText>
            <StyledQuoteName>
              <span>Kinga Rybicka</span>– {t("kinga.position")}
            </StyledQuoteName>
          </StyledQuoteWrapper>
        </StyledQuoteBox>
      </StyledSectionWrapper>
    </StyledCareerGrid>
  )
}
