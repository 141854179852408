import React, { useState, useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import { debounce } from "lodash"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import {
  ParagraphBold,
  ParagraphRegular,
  H2,
  Overline,
} from "../../shared/Typography/Typography"
import SwiperCore, { Navigation } from "swiper"
import { useTranslation } from "gatsby-plugin-react-i18next"
import GridContainer from "../../Layout/GridContainer"
import { device } from "../../shared/styles/breakpoints"
import Button from "../../shared/Button/Button"
import { ArrowLeftIcon } from "../../shared/Icons/ArrowLeftIcon"
import { ArrowRightIcon } from "../../shared/Icons/ArrowRightIcon"

SwiperCore.use([Navigation])

const SlidesWrapper = styled.div`
  display: flex;
  .timeline-swiper {
    padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
    padding-bottom: 276px;
    padding-right: 16px;
    height: auto;
    .swiper-slide {
      max-width: calc(100% - 16px);
      width: 380px;
    }
  }
`

const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const Date = styled.span`
  display: block;
  width: 100%;
  height: 54px;
  text-align: center;
  line-height: 54px;
  box-sizing: border-box;
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.black : theme.colors.yellow};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.yellow : theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[16]};
`

const Content = styled.div`
  padding: ${({ theme }) => theme.spaces[24]};
  flex: 1 0 auto;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.midGray};
`

const Title = styled(ParagraphBold)`
  margin: ${({ theme }) => ` 0 0 ${theme.spaces[8]}`};
`

const Text = styled(ParagraphRegular)`
  margin: 0;
`

const TimelineTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[40]}`};
  @media ${device.lg} {
    margin: ${({ theme }) => `0 0 ${theme.spaces[64]}`};
  }
`

const StyledNavigationItem = styled(Button)`
  position: absolute;
  bottom: ${({ theme }) => theme.spaces[48]};
  width: 64px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  justify-content: center;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

const PrevNavigation = styled(StyledNavigationItem)`
  left: 50%;
  transform: translateX(calc(-100% - 5px));
`

const NextNavigation = styled(StyledNavigationItem)`
  right: 50%;
  transform: translateX(calc(100% + 5px));
`

const PaginOverflow = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  scrollbar-width: none;
  position: absolute;
  bottom: 134px;
  height: 90px;
  left: 0;
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.lg} {
    overflow-x: hidden;
  }
`

const PaginWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 105px;
  padding: ${({ theme }) => `0 ${theme.spaces[44]}`};
  max-width: 1180px;
  box-sizing: border-box;
  height: 16px;
  transition: ${({ theme }) => theme.transition};

  @media ${device.lg} {
    justify-content: space-between;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    height: 2px;
    top: calc(50% - 1px);
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`

const PaginItem = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  cursor: pointer;

  &:after {
    transition: ${({ theme }) => theme.transition};
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ isActive }) => (isActive ? "16px" : "8px")};
    height: ${({ isActive }) => (isActive ? "16px" : "8px")};
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme, isActive }) =>
    isActive
      ? `4px solid ${theme.colors.yellow}`
      : `2px solid ${theme.colors.yellow}`};
    border-radius: 50%;
    box-sizing: border-box;
  }
`

const PaginLabel = styled(Overline)`
  position: absolute;
  top: ${({ isActive }) => (isActive ? `-36px` : "-32px")};
  left: 50%;
  transform: translateX(-50%);
  font-size: ${({ isActive, theme }) =>
    isActive ? theme.fontSizes[20] : theme.fontSizes[12]};
  line-height: ${({ isActive, theme }) =>
    isActive ? theme.lineHeights[20] : theme.lineHeights[12]};
  transition: ${({ theme }) => theme.transition};
`

const getPaginElements = (timelineSlides) => {
  const paginElements = []
  timelineSlides.forEach(el => {
    if (!paginElements.includes(el.year)) {
      paginElements.push(el.year)
    }
  })
  return paginElements
}

const SectionWrapper = styled.div``

export default function TimelineSlider ({ timelineSlides, ...props }) {
  const { t } = useTranslation()
  const [paddingLeft, setPaddingLeft] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(swiper?.activeIndex || 0)
  const [activePaginIndex, setActivePaginIndex] = useState(0)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const paginRef = useRef(null)
  const paginElements = getPaginElements(timelineSlides)

  const handleResize = useCallback(
    debounce(() => {
      const windowWidth = window.innerWidth
      const gridWidth = windowWidth <= 1180 ? windowWidth - 32 : 1180
      setPaddingLeft((windowWidth - gridWidth) / 2)
    }, 300),
    []
  )

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (swiper) swiper.slideTo(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    paginRef.current.scroll({
      top: 0,
      left: activePaginIndex ? activePaginIndex * 150 : 0,
      behavior: "smooth",
    })
  }, [activePaginIndex])

  const slideTo = year => {
    const found = timelineSlides.findIndex(el => el.year === year)
    setActiveIndex(found)
  }

  const handleSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex)
    const shouldChangeActiveIndex =
      timelineSlides[swiper.activeIndex].year !==
      paginElements[activePaginIndex]
    if (shouldChangeActiveIndex) {
      const newIndex = paginElements.findIndex(
        el => el === timelineSlides[swiper.activeIndex].year
      )
      setActivePaginIndex(newIndex)
    }
  }

  return (
    <SectionWrapper {...props}>
      <GridContainer>
        <TimelineTitle>{t("Poznaj naszą historię:")}</TimelineTitle>
      </GridContainer>
      <SlidesWrapper paddingLeft={paddingLeft}>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          freeMode={true}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          className="timeline-swiper"
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }}
          onSwiper={setSwiper}
          onSlideChange={swiper => handleSlideChange(swiper)}
          autoHeight={true}
        >
          {timelineSlides.map((el) => (
            <SwiperSlide key={el.contentful_id}>
              {({ isActive }) => (
                <SliderItem isActive={isActive}>
                  <Date isActive={isActive}>{`${el.month}.${el.year}`}</Date>
                  <Content>
                    <Title>{el.title}</Title>
                    <Text>{el.text.text}</Text>
                  </Content>
                </SliderItem>
              )}
            </SwiperSlide>
          ))}

          <PaginOverflow activeIndex={activePaginIndex} ref={paginRef}>
            <PaginWrapper>
              {paginElements.map((el, key) => {
                const isActiveElement = activePaginIndex === key
                return (
                  <PaginItem
                    key={key}
                    onClick={() => slideTo(el)}
                    isActive={isActiveElement}
                  >
                    <PaginLabel isActive={isActiveElement}>{el}</PaginLabel>
                  </PaginItem>
                )
              })}
            </PaginWrapper>
          </PaginOverflow>
          <PrevNavigation ref={navigationPrevRef}>
            <ArrowLeftIcon />
          </PrevNavigation>
          <NextNavigation ref={navigationNextRef}>
            <ArrowRightIcon />
          </NextNavigation>
        </Swiper>
      </SlidesWrapper>
    </SectionWrapper>
  )
}
