import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import SiteHeader from "../components/AboutUs/SiteHeader"
import StaffMembers from "../components/AboutUs/Staff/StaffMembers"
import Career from "../components/AboutUs/Career"
import YellowBox from "../components/shared/YellowBox/YellowBox"
import { device } from "../components/shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"
import TimelineSlider from "../components/AboutUs/TimelineSlider/TimelineSlider"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledAboutUsWrapper = styled.div``

const StyledYellowBox = styled(YellowBox)`
  max-width: 980px;
  @media ${device.lg} {
    margin: ${({ theme }) => `0 auto -${theme.spaces[96]} `};
  }
`

export default function AboutUsPage ({ data }) {
  const timelineData = data.allContentfulTimeline.nodes
  const { t } = useTranslation()
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])
  return (
    <Layout>
      <Seo title={t("O nas")} />
      <StyledAboutUsWrapper>
        <SiteHeader />
        <StaffMembers data-aos="fade-in" />
        <TimelineSlider timelineSlides={timelineData} data-aos="fade-in" />
        <Career data-aos="fade-in" />
        <StyledYellowBox data-aos="fade-in" />
      </StyledAboutUsWrapper>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulTimeline(filter: {node_locale: {eq: $language}}, sort: {fields: order}) {
      nodes {
        year
        month
        title
        text {
          text
        }
        contentful_id
      }
    }
  }
`
